import Image from "next/image";
import Container from "./container";
import heroImg from "../public/img/logo/logo.svg";
import heroDarkImg from "../public/img/logo/logo-dark.svg";
import scrollDown from "../public/img/scrolldown.gif";



const Hero = () => {
  return (
    <>
      <Container className="flex flex-wrap ">
        <div className="flex items-center justify-center w-full">
          <div className="max-full flex flex-col items-center justify-center">
            <div className="">
              <Image
                src={heroDarkImg}
                width="600"
                height="504"
                className={"block dark:hidden w-64 md:w-[600px]"}
                alt="BeamRail Logo"
                loading="lazy"
                quality={75}
              />
              <Image
                src={heroImg}
                width="600"
                height="504"
                className={"hidden dark:block w-64 md:w-[600px]"}
                alt="BeamRail Logo"
                loading="lazy"
                quality={75}
              />
            </div>
            <h1 className="mt-5 text-center text-md md:text-xl font-medium tracking-tight text-gray-800 lg:text-1xl  xl:text-3xl dark:text-white">
              Lighting The Path To Safer Communities
            </h1>
            <p className="py-4 mt-2 md:mt-12 text-center md:text-left text-md md:text-xl leading-normal text-blue-600 lg:text-1xl xl:text-3xl dark:text-gray-300">
              AI-Enhanced records and operations management solution for Law Enforcement.
            </p>
          </div>
        </div>
      </Container>
    </>
  );
}

export default Hero;