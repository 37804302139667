import Link from "next/link";
import ThemeChanger from "./DarkSwitch";

const Navbar = () => {

  return (
    <div className="w-full">
      <nav className="container relative flex sm:flex-row items-center justify-between p-8 mx-auto lg:justify-between xl:px-0">
        <div className="flex sm:flex-row items-center justify-between lg:w-auto">
          <Link href="/">
            <span className="flex items-center space-x-2 text-2xl font-medium text-blue-700 dark:text-gray-100">
              <span>Home</span>
            </span>
          </Link>
        </div>


        <ThemeChanger />

        <div className="space-x-4 lg:flex nav__item">
          <Link href="/contact" className="px-6 py-2 text-white bg-blue-600 rounded-md md:ml-5">
            Contact Us
          </Link>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
