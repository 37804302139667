import Link from "next/link";
import Image from "next/image";
import React from "react";
import Container from "./container";
import heroImg from "../public/img/logo/logo.svg";
import heroDarkImg from "../public/img/logo/logo-dark.svg";

export default function Footer() {
  const navigation = [
    "privacy",
    "terms",
    "about",
  ];
  const legal = ["Terms", "Privacy", "Legal"];
  return (
    <div className="relative">
      <Container>
        <div className="flex max-w-screen-xl flex-col justify-center items-center gap-10 pt-10 mx-auto mt-5 border-t border-gray-100 dark:border-trueGray-700 lg:grid-cols-5">
          <div className="lg:col-span-2">
            <div>
              {" "}
              <Link href="/" className="flex items-center space-x-2 text-2xl font-medium text-indigo-500 dark:text-gray-100">
              <Image
                src={heroDarkImg}
                width="200"
                height="504"
                className={"block dark:hidden"}
                alt="BeamRail Logo"
                loading="lazy"
              />
              <Image
                src={heroImg}
                width="200"
                height="504"
                className={"hidden dark:block"}
                alt="BeamRail Logo"
                loading="lazy"
              />
              </Link>
            </div>
          </div>

          <div>
            <div className="flex flex-row w-full -mt-2 -ml-3 lg:ml-0">
              {navigation.map((item, index) => (
                <Link key={index} href={item} className="w-full px-4 py-2 text-gray-500 rounded-md dark:text-gray-300 hover:text-indigo-500 focus:text-indigo-500 focus:bg-indigo-100 focus:outline-none dark:focus:bg-trueGray-700 capitalize">                 
                    {item}
                </Link>
              ))}
            </div>
          </div>

        </div>

        <div className="my-10 text-sm text-center text-gray-600 dark:text-gray-400">
          Copyright © {new Date().getFullYear()}.
        </div>
      </Container>
    </div>
  );
}